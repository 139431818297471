import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setFloor, setTime } from "../actions/floorActions";
import { createFloors, printTime, focus } from "../utils";
import Star from "../assets/images/star.svg";

const FloorSelection = props => {
    const [floors, setFloors] = useState([]);
    const selectBTN = useRef()
    const delayID = useRef();
    const setDelayID = value => delayID.current = value;

    useEffect(() => {
        setFloors(createFloors(25))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selection = (obj, e) => {
        e.persist()
        if (delayID) clearTimeout(delayID);
        focus(e, "add");
        let id = setTimeout(() => {
            clearTimeout(id);
            props.setTime(obj.time);
            focus(e, "remove")
            props.setFloor(obj.floor);
        }, 1000);
        setDelayID(id);
    }

    return (
        <main>
            <div className="floor-selection__container">
                {floors.map(obj => (
                    <button ref={selectBTN} className="floor-selection__button" key={`floor-${obj.floor}`} onClick={e => selection(obj, e)}>
                        <p className="floor-selection__button-floor-num">{obj.floor}</p>
                        <p className="floor-selection__button-floor-sec">{printTime(obj.time)}</p>
                    </button>
                ))}
            </div>
            <div className="floor-selection__container--bottom">
                <p className="floor-selection__desc">5 seconds per floor</p>
                <button className="floor-selection__button" disabled>
                    <div className="floor-selection__button-floor-num-container ">
                        <p className="floor-selection__button-floor-num">1</p>
                        <img className="floor-selection__button-star-icon" src={Star} alt="" />
                    </div>
                    <p className="floor-selection__button-floor-sec">0s</p>
                </button>
            </div>
        </main>
    )
}

const mapDispatchToProps = dispatch => ({
    setFloor: value => dispatch(setFloor(value)),
    setTime: value => dispatch(setTime(value))
})

export default connect(null, mapDispatchToProps)(FloorSelection);