export function createFloors(total) {
    const arr = [];
    [...Array(total - 1).keys()].reduce((accumulator, value) => {
        if (accumulator === 13) {
            ++accumulator
        }
        arr.push({
            floor: accumulator,
            time: (value + 1) * 5
        });
        return ++accumulator;
    }, 2);
    return arr.reverse();
}

export function printTime(time) {
    switch (time) {
        case 60:
            return `1m`;
        case 120:
            return `2m`;
        default:
            return `${time}s`
    }
}

export function countdown({ from, to, interval, direction = "down" }, callback) {
    let num = from;
    const id = setInterval(() => {
        if (direction === "down")--num;
        if (direction === "up")++num;
        callback(num);
        if (num === to) clearInterval(id);
    }, interval)

    return id;
}

export function focus(e, type) {
    let el = e.target;
    let time;
    if (el.tagName !== "BUTTON") el = e.target.parentElement;
    time = el.firstElementChild.nextElementSibling;
    switch (type) {
        case "add":
            el.classList.add("floor-selection__button--focus");
            time.classList.add("floor-selection__button-floor-sec--focus");
            break;
        case "remove":
            el.classList.remove("floor-selection__button--focus");
            time.classList.remove("floor-selection__button-floor-sec--focus");
            break;
        default:
            break;
    }
}