import React, { useEffect } from "react";
import FloorSelection from "../components/FloorSelection";

const Selection = () => {

    useEffect(() => {
        document.body.classList.remove("background__blue");
        document.body.classList.add("background__black")
    }, [])

    return (
        <React.Fragment>
            <FloorSelection />
        </React.Fragment>
    )
}

export default Selection;