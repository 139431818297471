import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

const SVGBackground = () => {

    const [time] = useState(600)

    useEffect(() => {
        const id = setTimeout(() => {
            document.body.classList.remove("background__black");
            document.body.classList.add("background__blue")
        }, time)

        return () => {
            clearTimeout(id)
        }
    }, [])

    const radius = useSpring({
        from: { r: 10 },
        to: { r: 1000 },
        config: { duration: time }
    });

    return (
        <React.Fragment>
            <svg viewBox="0 0 400 445" className="svg-background">
                <animated.circle
                    r="20"
                    cx="200"
                    cy="210"
                    fill="#5280FF"
                    style={radius}
                />
            </svg>
        </React.Fragment>
    )
}

export default SVGBackground;