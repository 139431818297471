import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { countdown } from "../utils";
import Logo from "../assets/images/logo.svg";
import ArrowUp from "../assets/images/arrow-up.svg";

const Header = props => {

    const [currentFloor, setCurrentFloor] = useState(1);
    const floorID = useRef();
    const setFloorID = value => floorID.current = value;

    // ON DISMOUNT
    useEffect(() => {
        return () => {
            clearInterval(floorID.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TIMER START
    useEffect(() => {
        if (props.startTimer && props.floor !== null) {
            startFloorCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startTimer]);

    // TIMER RESET
    useEffect(() => {
        if (props.resetTimer || props.floor === null) {
            clearInterval(floorID.current);
            setCurrentFloor(1);
        }
    }, [props.resetTimer, props.floor]);

    const startFloorCount = () => {
        const id = countdown({
            from: 1,
            to: props.floor,
            interval: 5000,
            direction: "up"
        }, num => {
            if (num === props.floor || num < 13) {
                setCurrentFloor(num)
            } else {
                setCurrentFloor(num + 1);
            }
        });
        setFloorID(id);
    }

    return (
        <header className="header">
            <img className="header__logo" src={Logo} alt="" />
            <article className="header__container">
                <p className="header__floor-num">{currentFloor}</p>
                <div className="header__circle-bg">
                    <img src={ArrowUp} alt="" />
                </div>
            </article>
        </header>
    )
}

const mapStateToDispatch = state => ({
    floor: state.floor.floor,
    resetTimer: state.timer.resetTimer,
    startTimer: state.timer.startTimer
})

export default connect(mapStateToDispatch)(Header);