import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setRevertResetTimer } from "../actions/timerActions";
import { useSpring, animated } from "react-spring";
import Timer from "../components/Timer";
import SVGBackground from "../components/SVGBackground";
import ResetButtons from "../components/ResetButtons";

const Elevator = props => {

    useEffect(() => {
        document.body.classList.remove("background__black");
        document.body.classList.add("background__blue")
    }, [])

    useEffect(() => {
        if (props.resetTimer) props.setRevertResetTimer()
    }, [props.resetTimer])

    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 300 },
        delay: 500
    });

    if (props.resetTimer) return <main></main>;

    return (
        <React.Fragment>
            <animated.main style={fadeIn}>
                <ResetButtons />
                <Timer />
            </animated.main>
            {props.startTimer ? <SVGBackground /> : null}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    resetTimer: state.timer.resetTimer,
    startTimer: state.timer.startTimer
});

const mapDispatchToProps = dispatch => ({
    setRevertResetTimer: () => dispatch(setRevertResetTimer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Elevator);